<template>
  <div class="links w-full">
    <h5 class="text-xl lg:text-2xl font-semibold">{{this.settings.popular_category_text}}</h5>

    <div class="links-content grid grid-cols-2 gap-4 mt-6">
      <button
        v-for="(category, i) in categories"
        :key="i"
        class="flex flex-col lg:flex-row items-start lg:items-center justify-start space-x-4 border border-solid rounded-lg bg-transparent py-4 px-5 lg:py-3 lg:px-4 font-semibold text-left text-lg lg:text-xl cursor-pointer"
        @click="handleSearch(category.value)"
      >
        <vs-avatar
          :src="category.icon"
          :icon="category.icon"
          color="rgba(207, 241, 233, 1)"
          class="links-icon m-0 mb-2 lg:mr-4 w-12 h-12">
        </vs-avatar>
        {{ category.label }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    handleSearch(category) {
      const userType = this.$route.path.split('/')[1]

      this.$router.push({
        name: `${userType}-search-resources`,
        query: { category }
      });
    }
  },
  computed: {
    categories() {
      return this.$store.state.resourceHub.filters.categories;
    },
    settings() {
      return this.$store.state.resourceHub.settings;
    }
  }
};
</script>

<style lang="scss">
.links {
  button {
    border-color: #e3ddd7;
    font-family: inherit;
  }
}
</style>
