<template>
  <div class="recent w-full">
    <Card 
      :slide="slideTo" 
      display-type="carousel" 
      :resources="resources" 
      :title="this.settings.recent_resources_text" 
    />
  </div>
</template>

<script>
import Card from "./Card.vue";
import ArrowButtons from "./ArrowButtons.vue";
import Filters from "./Filters.vue";

export default {
  components: {
    Card,
    ArrowButtons,
    Filters,
  },
  data() {
    return {
      slideTo: null
    };
  },
  computed: {
    resources() {
      return this.$store.state.resourceHub.resources.recent.results.map((i) => ({ ...i, isRecent: true }))
    },
    settings() {
      return this.$store.state.resourceHub.settings;
    }
  }
};
</script>
